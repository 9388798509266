import { FC } from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import loader from 'loader.json';

interface Props {
  displayed: boolean;
}

const LoaderWrapper = styled.div<{ $displayed: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.9);
  pointer-events: none;
  transition: opacity 200ms ease-in-out;
  opacity: ${({ $displayed }) => ($displayed ? 1 : 0)};
`;

const Loader: FC<Props> = ({ displayed }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <LoaderWrapper $displayed={displayed}>
      <Lottie options={defaultOptions} width="46" height="46" />
    </LoaderWrapper>
  );
};

export default Loader;

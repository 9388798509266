/* eslint-disable no-console */

declare function webviewLoaded(): void;
declare function webviewNotLoaded(): void;
declare function slideNext(): void;

const sendEventWebviewLoaded = () => {
  try {
    webviewLoaded();
  } catch (e) {
    console.log('webviewLoaded - only available for webview.');
  }
};

const sendEventWebviewNotLoaded = () => {
  try {
    webviewNotLoaded();
  } catch (e) {
    console.log('webviewNotLoaded - only available for webview.');
  }
};

const sendEventSlideNext = () => {
  try {
    slideNext();
  } catch (e) {
    console.log('slideNext - only available for webview.');
  }
};

export {
  sendEventWebviewLoaded,
  sendEventWebviewNotLoaded,
  sendEventSlideNext,
};

import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoImage } from 'images/logo.svg';

interface Props {
  visible: boolean;
}

const LogoWrapper = styled.div<{ $visible: boolean }>`
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  z-index: 30;
  width: 100%;
  padding: 0 50px;
  filter: drop-shadow(0px 4px 4px #00000026);
  transition: ${({ $visible }) => ($visible ? 'none' : '200ms opacity')};
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};

  .cls-1 {
    fill: ${({ theme }) => theme.primary};
  }
`;

const Logo: FC<Props> = ({ visible }) => {
  return (
    <LogoWrapper $visible={visible}>
      <LogoImage />
    </LogoWrapper>
  );
};

export default Logo;

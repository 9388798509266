import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { useBrandliftContext } from 'contexts/BrandliftContext';
import Loader from 'components/Loader';
import { fetchQuestionAnswer } from 'utils/api';
import { getThemeCookie, setThemeCookie } from 'utils/cookies';

interface Props {
  children?: ReactNode;
  uuid: string;
  submitCallback: () => void;
}

interface IFormContext {
  formRef: React.RefObject<HTMLFormElement>;
  isFormValid: boolean;
  formSubmitted: boolean;
  validateForm: () => void;
  submitQuestion: (value?: string | number) => void;
}

const defaultFormContext: IFormContext = {
  formRef: { current: null },
  isFormValid: false,
  formSubmitted: false,
  validateForm: () => {},
  submitQuestion: () => {},
};

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
`;

const FormContext = createContext<IFormContext>(defaultFormContext);

export const useFormContext = () => {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }

  return context;
};

export const FormProvider: FC<Props> = ({ children, uuid, submitCallback }) => {
  const { theme } = useBrandliftContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loaderDisplayed, setLoaderDisplayed] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validateForm = () => {
    const inputs = [
      ...formRef.current!.querySelectorAll<HTMLInputElement>(
        'input:not([type="submit"])',
      ),
    ];

    setIsFormValid(
      inputs.some((input) => input.checked || input.value.length >= 3),
    );
  };

  const preventDefaultSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    (document.activeElement as HTMLElement).blur();
  };

  const submitQuestion = useCallback(
    async (value?: string | number) => {
      let formData;

      setFormSubmitted(true);

      if (value) {
        formData = JSON.stringify({
          answers: {
            [uuid]: value,
          },
        });
      } else {
        const inputs = [...formRef.current!.querySelectorAll('input')];
        const textInputs = inputs.filter((input) => input.type === 'text');

        if (textInputs.length) {
          const filledInputs = textInputs.filter((input) => input.value);
          formData = JSON.stringify({
            answers: {
              [uuid]: filledInputs.reduce<Record<string, string>>(
                (acc, input) => {
                  acc[input.dataset.uuid as string] = input.value;
                  return acc;
                },
                {},
              ),
            },
          });
        } else {
          const checkedInputs = inputs.filter((input) => input.checked);
          formData = JSON.stringify({
            answers: {
              [uuid]: checkedInputs.reduce<Record<string, boolean>>(
                (acc, input) => {
                  acc[input.value] = true;
                  return acc;
                },
                {},
              ),
            },
          });
        }
      }

      let answered = false;
      setTimeout(() => {
        if (!answered) setLoaderDisplayed(true);
      }, 4000);

      await fetchQuestionAnswer(formData);
      answered = true;
      setLoaderDisplayed(false);

      if (!getThemeCookie()) setThemeCookie(theme);

      setTimeout(() => {
        submitCallback();
      }, 500);
    },
    [uuid, theme, submitCallback],
  );

  const contextValue = useMemo(
    () => ({
      formRef,
      isFormValid,
      formSubmitted,
      validateForm,
      submitQuestion,
    }),
    [formRef, formSubmitted, isFormValid],
  );

  return (
    <FormContext.Provider value={contextValue}>
      <StyledForm ref={formRef} onSubmit={(e) => preventDefaultSubmit(e)}>
        {children}
      </StyledForm>
      <Loader displayed={loaderDisplayed} />
    </FormContext.Provider>
  );
};

import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
  FC,
} from 'react';
import { ThemeProvider } from 'styled-components';
import { IQuestion } from 'types/Question';
import { ITheme } from 'types/Theme';
import { getThemeCookie } from 'utils/cookies';
import { fetchQuestions } from 'utils/api';
import {
  sendEventWebviewLoaded,
  sendEventWebviewNotLoaded,
} from 'utils/events';

interface Props {
  children: ReactNode;
}

interface IBrandliftContext {
  questions: IQuestion[] | null;
  theme: ITheme;
  setQuestions: (newValue: [] | null) => void;
}

const defaultBrandContext = {
  questions: null,
  theme: { primary: '#5735E0' },
  setQuestions: () => {},
};

const BrandliftContext = createContext<IBrandliftContext>(defaultBrandContext);

export const useBrandliftContext = () => {
  const context = useContext(BrandliftContext);

  if (!context) {
    throw new Error('useBrandlift must be used within a BrandliftProvider');
  }

  return context;
};

export const BrandliftProvider: FC<Props> = ({ children }) => {
  const [webviewLoaded, setWebviewLoaded] = useState(false);
  const [questions, setQuestions] = useState<IQuestion[] | null>(null);
  const [theme, setTheme] = useState(defaultBrandContext.theme);

  const getQuestions = async () => {
    try {
      const data = await fetchQuestions();
      setQuestions(data);
      setWebviewLoaded(true);
      sendEventWebviewLoaded();
    } catch (e) {
      sendEventWebviewNotLoaded();
    }
  };

  useEffect(() => {
    const themeCookie = getThemeCookie();

    if (!themeCookie) {
      getQuestions();
    } else {
      setTheme(themeCookie);
      setQuestions(null);
      setWebviewLoaded(true);
      sendEventWebviewLoaded();
    }
  }, []);

  const contextValue = useMemo(
    () => ({ questions, theme, setQuestions }),
    [questions, theme],
  );

  return (
    <BrandliftContext.Provider value={contextValue}>
      {webviewLoaded && <ThemeProvider theme={theme}>{children}</ThemeProvider>}
    </BrandliftContext.Provider>
  );
};

import { FC } from 'react';
import styled from 'styled-components';
import { IQuestionResponse } from 'types/Question';
import { useFormContext } from 'contexts/FormContext';
import unchecked from 'images/unchecked.svg';
import checked from 'images/checked.svg';

interface Props {
  response: IQuestionResponse;
}

const StyledInputCheckbox = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-width: 200px;
  min-height: 36px;
  margin: 0 0 10px;
  padding: 9px 13px 9px 19px;
  background-color: #f5f5f5;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 18px;
  line-height: 1;

  input {
    display: none;
    appearance: none;
  }

  &::after {
    content: '';
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    background-image: url(${unchecked});
  }

  &:has(input:checked) {
    &::after {
      background-image: url(${checked});
    }
  }
`;

const InputCheckbox: FC<Props> = ({ response }) => {
  const { uuid, text } = response;
  const { formSubmitted, validateForm } = useFormContext();

  return (
    <StyledInputCheckbox>
      <input
        type="checkbox"
        value={uuid}
        onChange={() => validateForm()}
        disabled={formSubmitted}
      />
      {text}
    </StyledInputCheckbox>
  );
};

export default InputCheckbox;

interface ParamsObject {
  [key: string]: string;
}

const questionsBaseURL = `${process.env.REACT_APP_API_URL}webview?d=`;
const questionAnswerBaseURL = `${process.env.REACT_APP_API_URL}answers?u=`;

const getUrlParams = () => {
  const params = new URLSearchParams(window.location.search);
  const paramsObj: ParamsObject = {};

  params.forEach((value, key) => {
    paramsObj[key] = value;
  });

  return paramsObj;
};

const fetchQuestions = async () => {
  const { d } = getUrlParams();

  if (!d) throw new Error('Missing "d" parameter');

  try {
    const res = await fetch(questionsBaseURL + d);

    if (!res.ok) {
      throw new Error(`Response status: ${res.status}`);
    }

    if (res.status === 204) return [];

    const data = await res.json();
    return data;
  } catch (error) {
    throw new Error();
  }
};

const fetchQuestionAnswer = async (formData: string) => {
  const { u } = getUrlParams();
  const res = await fetch(questionAnswerBaseURL + u, {
    method: 'POST',
    body: formData,
  });

  return res;
};

export { fetchQuestions, fetchQuestionAnswer };

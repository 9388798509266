import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { IQuestion } from 'types/Question';

interface Props {
  questions: IQuestion[];
  position: number;
}

const DotsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 18px;
  width: fit-content;
  margin: 16px auto 0;
`;

const ActiveDot = styled.div<{ $position: number; $questions: IQuestion[] }>`
  position: absolute;
  left: ${({ $position, $questions }) =>
    $position === 0
      ? 0
      : `calc((${$position / ($questions.length - 1)} * (100% - 8px)))`};
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  transition: 200ms left ease-in-out;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
`;

const Dots: FC<Props> = ({ questions, position }) => {
  return (
    <DotsWrapper>
      <ActiveDot $questions={questions} $position={position} />
      {questions.map(() => (
        <Dot key={uuidv4()} />
      ))}
    </DotsWrapper>
  );
};

export default Dots;

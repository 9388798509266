import { FC } from 'react';
import styled from 'styled-components';
import { useBrandliftContext } from 'contexts/BrandliftContext';
import Card from 'components/Card';
import Logo from 'components/Logo';
import thanksAnswered from 'images/thanks-answered.svg';
import thanksNoQuestions from 'images/thanks-no-questions.svg';

const ThanksWrapper = styled.div`
  height: 480px;
`;

const ThanksInnerWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 480px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.primary};
`;

const Thanks: FC = () => {
  const { questions } = useBrandliftContext();

  return (
    <ThanksWrapper>
      <Card background={true}>
        <Logo visible />
        <ThanksInnerWrapper>
          <img src={!questions ? thanksAnswered : thanksNoQuestions} alt="" />
        </ThanksInnerWrapper>
      </Card>
    </ThanksWrapper>
  );
};

export default Thanks;

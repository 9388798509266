import { FC } from 'react';
import styled from 'styled-components';
import { IQuestionAvailableQuestion } from 'types/Question';
import { useFormContext } from 'contexts/FormContext';

interface Props {
  question: IQuestionAvailableQuestion;
}

const StyledInputText = styled.input`
  width: 100%;
  height: 44px;
  margin-bottom: 14px;
  padding: 11px 12px;
  background-color: #3333330d;
  border: 1px solid #3333330d;
  border-radius: 4px;
  color: transparent;
  text-shadow: 0 0 0 #000;

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.primary};
    outline: none;
  }

  &:disabled {
    opacity: 1;
  }
`;

const InputText: FC<Props> = ({ question }) => {
  const { formSubmitted, validateForm } = useFormContext();

  return (
    <StyledInputText
      type="text"
      placeholder={question.question}
      onInput={() => validateForm()}
      disabled={formSubmitted}
      data-uuid={question.uuid}
    />
  );
};

export default InputText;

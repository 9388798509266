import { FC, useState } from 'react';
import styled from 'styled-components';
import { useBrandliftContext } from 'contexts/BrandliftContext';
import Card from 'components/Card';
import Logo from 'components/Logo';
import Dots from 'components/Dots';
import QuestionSingle from 'components/QuestionSingle';
import { sendEventSlideNext } from 'utils/events';

const getTransform = (index: number, activeIndex: number) => {
  const basePosition = index > activeIndex ? index * 100 : index * -100;
  const offset = (activeIndex - index) * 20;
  const scale = 1 - (activeIndex - index) * 0.05;
  return `translateX(calc(${basePosition}% - ${offset}px)) scale(${scale})`;
};

const StyledQuestionsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  width: 280px;
  margin: auto;
`;

const SingleQuestionWrapper = styled.div<{ $index: number; $position: number }>`
  transform: ${({ $index, $position }) => getTransform($index, $position)};
  opacity: ${({ $index, $position }) => ($index > $position ? 0 : 1)};
  transition: 200ms all;
`;

const QuestionsWrapper: FC = () => {
  const { questions, setQuestions } = useBrandliftContext();
  const [position, setPosition] = useState(0);

  const changeQuestion = () => {
    setPosition((prevPosition) => {
      if (prevPosition + 1 === questions!.length) {
        sendEventSlideNext();

        setTimeout(() => {
          setQuestions(null);
        }, 300);
      } else {
        return prevPosition + 1;
      }
      return prevPosition;
    });
  };

  return (
    <>
      <StyledQuestionsWrapper>
        {questions!.map((question, index) => (
          <SingleQuestionWrapper
            key={question.uuid}
            $index={index}
            $position={position}
          >
            <Card>
              <Logo visible={index === position} />
              <QuestionSingle
                question={question}
                changeQuestion={changeQuestion}
              />
            </Card>
          </SingleQuestionWrapper>
        ))}
      </StyledQuestionsWrapper>
      {questions!.length > 1 && (
        <Dots questions={questions!} position={position} />
      )}
    </>
  );
};

export default QuestionsWrapper;

import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useBrandliftContext } from 'contexts/BrandliftContext';
import QuestionsWrapper from 'components/QuestionsWrapper';
import Thanks from 'components/Thanks';

const ScaledWrapper = styled.div<{ $scale: number }>`
  transform: scale(${({ $scale }) => $scale});
  transform-origin: top center;
  padding-top: 50px;
`;

const App = () => {
  const { questions } = useBrandliftContext();
  const [scale, setScale] = useState(1);
  const scaleWrapperRef = useRef<HTMLDivElement | null>(null);

  const updateScale = (scaleWrapper: HTMLDivElement) => {
    const targetHeight = window.innerHeight - 40;
    const newScale = targetHeight / scaleWrapper.clientHeight;
    setScale(newScale);
  };

  useEffect(() => {
    const scaleInterval = setInterval(() => {
      if (scaleWrapperRef.current && window.innerHeight > 0) {
        const swiperWrapper = scaleWrapperRef.current;

        updateScale(swiperWrapper);

        window.addEventListener('orientationchange', () => {
          setTimeout(() => updateScale(swiperWrapper), 100);
        });

        clearInterval(scaleInterval);
      }
    }, 100);
  }, []);

  return (
    <ScaledWrapper $scale={scale} ref={scaleWrapperRef}>
      {questions && questions.length ? <QuestionsWrapper /> : <Thanks />}
    </ScaledWrapper>
  );
};

export default App;

import Cookies from 'universal-cookie';
import { ITheme } from 'types/Theme';

const cookies = new Cookies();

const getThemeCookie = () => cookies.get('webviewTheme');
const setThemeCookie = (theme: ITheme) => cookies.set('webviewTheme', theme);
const removeThemeCookie = () => cookies.remove('webviewTheme');

export { getThemeCookie, setThemeCookie, removeThemeCookie };

import { FC, useRef, useState, Fragment } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'contexts/FormContext';
import translations from 'translations';

interface Props {
  keys: string[];
  type: string;
}

const InputPinWrapper = styled.div<{ $valid: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  input {
    width: 32px;
    height: 44px;
    padding: 0;
    background: #3333330d;
    border: 1px solid ${({ $valid }) => ($valid ? '#33333326' : '#ED4A4A')};
    border-radius: 4px;
    outline: 0;
    font-size: 24px;
    text-align: center;
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  input:focus {
    border-color: #34b57b;
    caret-color: transparent;
  }

  input:disabled {
    opacity: 1;
  }
`;

const Error = styled.p`
  text-align: center;
  font-size: 12px;
`;

const InputPin: FC<Props> = ({ keys, type }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { formRef, formSubmitted, submitQuestion } = useFormContext();
  const [error, setError] = useState<string | null>(null);

  const getInputs = () => {
    const currentInput = document.activeElement as HTMLInputElement;
    const prevInput =
      inputRefs.current[inputRefs.current.indexOf(currentInput) - 1];
    const nextInput =
      inputRefs.current[inputRefs.current.indexOf(currentInput) + 1];

    return {
      currentInput,
      prevInput,
      nextInput,
    };
  };

  const validatePin = () => {
    if (type === 'zipcode') {
      const pinString = inputRefs.current.map(({ value }) => value).join('');
      const pinStringWithHyphen = `${pinString.slice(0, 2)}-${pinString.slice(2)}`;
      submitQuestion(pinStringWithHyphen);
    } else {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const pinString = inputRefs.current.map(({ value }) => value).join('');
      const date = new Date(pinString);
      const year = date.getFullYear();
      const age = currentYear - year;

      if (age > 0 && age < 18) {
        setError(translations.errors.tooYoung);
        formRef.current!.reset();
      } else if (age < 0) {
        setError(translations.errors.notBornYet);
        formRef.current!.reset();
      } else if (age > 120) {
        setError(translations.errors.tooOld);
        formRef.current!.reset();
      } else {
        submitQuestion(parseInt(pinString, 10));
      }
    }
  };

  const handleInput = () => {
    const { currentInput, nextInput } = getInputs();
    const allInputsFilled = !inputRefs.current.some((input) => !input.value);

    currentInput.value = currentInput.value.replace(/[^0-9]/g, '');

    if (allInputsFilled) {
      currentInput.blur();
      validatePin();
    } else if (currentInput.value) {
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleBackspaceKeyPress = () => {
    const { currentInput, prevInput } = getInputs();
    const nextInputs = inputRefs.current.filter(
      (input, index) => index > inputRefs.current.indexOf(currentInput),
    );
    const currentInputValue = currentInput.value;

    currentInput.value = '';

    if (!currentInputValue && prevInput) {
      prevInput.focus();
      prevInput.value = '';
    }

    if (nextInputs) {
      nextInputs.forEach((input) => {
        const newInput = input;
        newInput.value = '';
      });
    }
  };

  const handleDigitKeyPress = (e: React.KeyboardEvent) => {
    const { currentInput, nextInput } = getInputs();

    if (currentInput.value) {
      e.preventDefault();
      currentInput.value = e.key;
      nextInput.focus();
    }
  };

  const handleSpecificKeyPresses = (e: React.KeyboardEvent) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      handleBackspaceKeyPress();
    }

    if (!isNaN(Number(e.key))) {
      handleDigitKeyPress(e);
    }
  };

  return (
    <>
      <InputPinWrapper $valid={!error}>
        {keys.map((value, index) => (
          <Fragment key={value}>
            {type === 'zipcode' && index === 2 && ' - '}
            <input
              type="tel"
              pattern="[0-9]"
              inputMode="numeric"
              maxLength={1}
              ref={(el) => {
                inputRefs.current[index] = el as HTMLInputElement;
              }}
              onFocus={() => setError(null)}
              onInput={() => handleInput()}
              onKeyDown={(e) => handleSpecificKeyPresses(e)}
              disabled={formSubmitted}
            />
          </Fragment>
        ))}
      </InputPinWrapper>
      {error && <Error>{error}</Error>}
    </>
  );
};

export default InputPin;

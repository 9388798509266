import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
  background?: boolean | undefined;
}

const StyledCard = styled.div<{ $background: boolean | undefined }>`
  position: relative;
  width: 280px;
  height: 100%;
  min-height: 480px;
  margin: 0 auto;
  background-color: ${({ $background, theme }) =>
    $background ? theme.primary : '#fff'};
  border: 5px solid ${({ theme }) => theme.primary};
  border-radius: 14px;
`;

const Card: FC<Props> = ({ children, background }) => {
  return <StyledCard $background={background}>{children}</StyledCard>;
};

export default Card;

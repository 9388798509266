import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import {
  IQuestion,
  IQuestionAvailableQuestion,
  IQuestionResponse,
} from 'types/Question';
import { FormProvider } from 'contexts/FormContext';
import InputRadio from 'components/inputs/InputRadio';
import InputText from 'components/inputs/InputText';
import InputPin from 'components/inputs/InputPin';
import InputSubmit from 'components/inputs/InputSubmit';
import InputCheckbox from 'components/inputs/InputCheckbox';

interface Props {
  question: IQuestion;
  changeQuestion: () => void;
}

const QuestionWrapper = styled.div`
  overflow: hidden;
  padding: 80px 18px 30px;
`;

const QuestionText = styled.p`
  display: block;
  margin: 0 auto 18px;
  text-align: center;
`;

const QuestionImage = styled.img`
  display: block;
  width: auto;
  max-height: 60px;
  margin: 0 auto 18px;
`;

const renderFields = (
  type: number,
  availableResponses: IQuestionResponse[],
  availableQuestions: IQuestionAvailableQuestion[],
) => {
  switch (type) {
    case 1:
      return availableResponses.map((response) => (
        <InputRadio key={response.uuid} response={response} />
      ));
    case 2:
      return availableResponses.map((response) => (
        <InputRadio
          key={response.uuid}
          response={{ ...response, buttonType: 3 }}
        />
      ));
    case 3:
      return (
        <>
          {availableResponses.map((response) => (
            <InputCheckbox key={response.uuid} response={response} />
          ))}
          <InputSubmit />
        </>
      );
    case 4:
      return (
        <>
          {availableQuestions.map((question) => (
            <InputText key={question.uuid} question={question} />
          ))}
          <InputSubmit />
        </>
      );
    case 5:
      return (
        <InputPin type="birthyear" keys={[...Array(4)].map(() => uuidv4())} />
      );
    case 6:
      return (
        <InputPin type="zipcode" keys={[...Array(5)].map(() => uuidv4())} />
      );
    default:
      return null;
  }
};

const QuestionSingle: FC<Props> = ({ question, changeQuestion }) => {
  const {
    uuid,
    preTitle,
    question: questionText,
    subtitle,
    image,
    type,
    availableResponses,
    availableQuestions,
  } = question;

  return (
    <QuestionWrapper>
      <div>
        {preTitle && (
          <QuestionText dangerouslySetInnerHTML={{ __html: preTitle }} />
        )}
        <QuestionText dangerouslySetInnerHTML={{ __html: questionText }} />
        {subtitle && (
          <QuestionText dangerouslySetInnerHTML={{ __html: subtitle }} />
        )}
        {image && <QuestionImage src={image} />}
      </div>
      <FormProvider uuid={uuid} submitCallback={changeQuestion}>
        {renderFields(type, availableResponses!, availableQuestions!)}
      </FormProvider>
    </QuestionWrapper>
  );
};

export default QuestionSingle;

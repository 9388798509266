import { FC } from 'react';
import styled from 'styled-components';
import { IQuestionResponse } from 'types/Question';
import { useFormContext } from 'contexts/FormContext';

interface Props {
  response: IQuestionResponse;
}

const StyledInputRadio = styled.label`
  position: relative;
  z-index: 10;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 200px;
  max-width: 100%;
  height: 36px;
  margin: 0 0 10px;
  padding: 9px 18px 8px;
  border-radius: 18px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  transition: 200ms all;

  input {
    display: none;
    appearance: none;
  }
`;

const DefaultInputRadio = styled(StyledInputRadio)`
  background: linear-gradient(
    90deg,
    #f5f5f5 0%,
    #f5f5f5 50%,
    ${({ theme }) => theme.primary} 50%,
    ${({ theme }) => theme.primary} 100%
  );
  background-size: 200%;
  border: 1px solid ${({ theme }) => theme.primary};

  &:has(input:checked) {
    background-position: 100% 0%;
    text-shadow: 0px 0px 1px #fff;
    color: #fff;

    &::after {
      width: 100%;
    }
  }
`;

const FlatInputRadio = styled(StyledInputRadio)`
  background-color: #fff;
  border: 0;

  &:has(input:checked) {
    background-color: #fff;
    text-shadow: 0px 0px 1px ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
  }
`;

const ShortInputRadio = styled(DefaultInputRadio)`
  width: auto;
  min-width: 42px;
  max-width: 65px;
  margin: 0 5px 10px;
`;

const InputRadio: FC<Props> = ({ response }) => {
  const { uuid, buttonType, text } = response;
  const { formSubmitted, submitQuestion } = useFormContext();

  return (
    <>
      {buttonType === 1 && (
        <DefaultInputRadio>
          <input
            type="radio"
            value={uuid}
            onClick={() => submitQuestion()}
            disabled={formSubmitted}
          />
          {text}
        </DefaultInputRadio>
      )}
      {buttonType === 2 && (
        <FlatInputRadio>
          <input
            type="radio"
            value={uuid}
            onClick={() => submitQuestion()}
            disabled={formSubmitted}
          />
          {text}
        </FlatInputRadio>
      )}
      {buttonType === 3 && (
        <ShortInputRadio>
          <input
            type="radio"
            value={uuid}
            onClick={() => submitQuestion()}
            disabled={formSubmitted}
          />
          {text}
        </ShortInputRadio>
      )}
    </>
  );
};

export default InputRadio;

import { FC } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'contexts/FormContext';
import translations from 'translations';

const StyledSubmitInput = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 41px;
  margin: 0 auto;
  background-color: ${({ disabled, theme }) =>
    disabled ? '#33333366' : theme.primary};
  border: 0;
  border-radius: 13px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
`;

const SubmitInput: FC = () => {
  const { isFormValid, formSubmitted, submitQuestion } = useFormContext();

  return (
    <StyledSubmitInput
      type="submit"
      value={translations.submitText}
      disabled={!isFormValid || formSubmitted}
      onClick={() => submitQuestion()}
    />
  );
};

export default SubmitInput;

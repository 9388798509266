import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import App from 'App';
import { BrandliftProvider } from 'contexts/BrandliftContext';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
  <React.StrictMode>
    <BrandliftProvider>
      <App />
    </BrandliftProvider>
  </React.StrictMode>,
);

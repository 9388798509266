const translations = {
  submitText: 'Wyślij',
  textPlaceholder: 'Wpisz odpowiedź',
  errors: {
    notBornYet: 'Prawdopodobnie nie ma Cię jeszcze na świecie ﻿😉',
    tooYoung: 'Musisz mieć 18 lat, aby wypełnić ankietę',
    tooOld: 'Prawdopodobnie już nie żyjesz ﻿😉',
  },
};

export default translations;
